<template>
  <v-app
          style="background:#474747"
      >

    <app-header></app-header>

    <v-main
      style="background:#474747"
        >
        <hello-world
      style="background:#474747"
            >
      </hello-world>
    </v-main>

    <app-footer fluid></app-footer>

        <v-card
      flat
      tile
      class="black white--text text-left flex"
      fluid
    >
      <v-card-text class="white--text text-center">
          <!--<span class="font-weight-medium">WANAX</span> | <span class="font-weight-regular">Archäologische Dienstleistungen</span>-->
          <span class="font-weight-medium">WANAX</span>
       &nbsp; | &nbsp;
        <a><span class="font-weight-light" style="color:white" @click="openImpressum.callback()">Impressum</span></a>
        · <a><span class="font-weight-light" style="color:white" @click="openDatenschutz.callback()">Datenschutz</span></a>
      </v-card-text>
        </v-card>

<v-dialog v-model="impressumDialog" max-width="600">
        <v-card>
        <v-card-title>
          <h2>Impressum</h2>
        </v-card-title>
        <v-card-text class="pb-0">
            <impressum-text class="pa-0"></impressum-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="impressumDialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>


<v-dialog v-model="datenschutzDialog" max-width="850">
        <v-card>
        <v-card-title>
            <!--<span class="text-h5">Datenschutz</span>-->
          <h2>Datenschutz</h2>
        </v-card-title>
        <v-card-text class="pb-0">
            <datenschutz-text class="pa-0"></datenschutz-text>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="grey darken-1"
            text
            @click="datenschutzDialog = false"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>


  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import ImpressumText from '@/components/ImpressumText';
import DatenschutzText from '@/components/DatenschutzText';

export default {
  name: 'App',

  components: {
    HelloWorld,
    AppHeader,
    AppFooter,
    ImpressumText,
    DatenschutzText,
  },

  data: () => ({
      impressumDialog: false,
      openImpressum: {
          callback: () => { window.openImpressumDialog() }},
      datenschutzDialog: false,
      openDatenschutz: {
          callback: () => { window.openDatenschutzDialog() }},
  }),

mounted() {
  window.openImpressumDialog = () => {
	this.impressumDialog = true;
  };
  window.closeImpressumDialog = () => {
	this.impressumDialog = false;
    this.$el.scrollTop = 0;
  };
  window.openDatenschutzDialog = () => {
	this.datenschutzDialog = true;
  };
  window.closeDatenschutzDialog = () => {
	this.datenschutzDialog = false;
    this.$el.scrollTop = 0;
  };
},
};
</script>
