<template>
            <v-container>
                <!--
            <p>
            WANAX<br>
            Konstantin Teichmann<br>
            Goethestraße 13<br>
            97072 Würzburg
            </p>

            <p>
            E-Mail: webmaster@konstantinteichmann.de
            </p>
                -->


                <h2 class="mb-1">Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
<p>WANAX<br />
Konstantin Teichmann<br />
Goethestraße 13<br />
97072 Würzburg</p>


<h2 class="mb-1">Kontakt</h2>
<p>Telefon: +&thinsp;49 931 730 480 59<br />

          E-Mail: <a style="color:#00000099;text-decoration:none" href="mailto:info@wanax.de"><span>info@wanax.de</span></a>
          </p>


            </v-container>
</template>

<script>

export default {
  name: 'ImpressumText',

};
</script>

<style scoped>
p {
    text-align: justify;
    text-justify: none;
  hyphens: auto;
  -moz-hyphens: auto;
}
</style>

