<template>
  <v-footer fluid padless>

        <v-card
      flat
      tile
      class="grey darken-4 white--text text-center"
      fluid
    >
    <!--<v-container fluid>-->
    <v-row fluid align="center" justify="center" class="my-0">
      <v-col cols="2">
        <v-img
          :src="require('../assets/logo.png')"
          class="my-2 ml-2"
          contain
          height="70"
          min-width="70"
        />
      </v-col>

      <v-col class="mb-0">
        <h1 
            class="display-2 font-weight-light mb-0 text-left"
    :class="{'ml-7': $vuetify.breakpoint.smAndDown, 'ml-0': $vuetify.breakpoint.mdAndUp}"
            >
          WANAX
        </h1>
        <!--
        <h2 class="display-1 font-weight-light mb-0">
          Archäologische Dienstleistungen
        </h2>
        -->

      </v-col>


    </v-row>
    <!--</v-container>-->
      </v-card>


  </v-footer>
</template>

<script>

export default {
  name: "AppHeader",
}

</script>
