<template>
    <!--height="500"-->
  <v-carousel
      hide-delimiters
      height="600"
      :cycle="true"
      interval="5000"
      dark
      style="background:#474747"
  >
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
      style="background:#474747"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    name: 'ImageCarousel',

      //ul21a, hb21ab, gbh1, deisen02-01, ih21, bw16, bw17, bsb18, ul20a
    data () {
      return {
        items: [
          {
            src: require('../assets/img/ul21a_01_img_4790.jpg'),
          },
          {
            src: require('../assets/img/ul21a_02_k1p_5354.jpg'),
          },
          {
            src: require('../assets/img/ul21a_03_img_7544.jpg'),
          },
          {
            src: require('../assets/img/hb21ab_k1p_4758.jpg'),
          },
          {
            src: require('../assets/img/gbh1_01_tex_m3.jpg'),
          },
          {
            src: require('../assets/img/gbh1_02_faces_m3.jpg'),
          },
          {
            src: require('../assets/img/deisenstein_02_snapshot_f_m3-01.jpg'),
          },
          {
            src: require('../assets/img/deisenstein_01_snapshot_f-tex_m3-02.jpg'),
          },
          {
            src: require('../assets/img/ih21ab_pl-02_grab_3d_tex-f_m5-00.jpg'),
          },
          {
            src: require('../assets/img/bw16_sn1pr.jpg'),
          },
          {
            src: require('../assets/img/bw17a.jpg'),
          },
          {
            src: require('../assets/img/bw17a_04_blatt6_a3_500dpi.jpg'),
          },
          {
            src: require('../assets/img/sb18a_collage-01_a3_gs.jpg'),
          },
          {
            src: require('../assets/img/ul20a_01_dji_0192.jpg'),
          },
          {
            src: require('../assets/img/ul20a_02_dji_0202.jpg'),
          },
        ],
      }
    },
  }
</script>
